import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector, useAppDispatch } from "../../../hook";
import "./Pricing.css";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import { useSelector } from "react-redux";
import { callToaster } from "../../../Features/authSlice";
import { PaymentModal } from "./PaymentModal";
import { plans, currentPlan, cancelPlan } from "../../../Features/PlansSlice";
import { Loader } from "../RepeatedComponent/Loader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CancelSubscriptionPopup } from "./CancelSubscriptionPopup";
import { CheckMarkIcon } from "../RepeatedComponent/CheckMarkIcon";

export const Pricing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(0);
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const reduxData = useSelector((state: any) => {
    return state;
  });
  const [subscriptionMonth, setSubscriptionMonth] = useState<any>(0);
  const allPlans: any = useAppSelector(
    (state: any) => state.plans.subscriptions
  );

  const status = useAppSelector((state: any) => state.auth.status);
  const activePlan: any = useAppSelector((state: any) => state.plans.message);

  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  useEffect(() => {
    setLoading(1);
    dispatch(plans()).then((plans: any) => {
      if (
        plans?.payload?.success &&
        Array.isArray(plans.payload.subscriptions) &&
        plans.payload.subscriptions.length
      ) {
        setSubscriptionMonth(plans.payload.subscriptions[1]);
      }
      dispatch(currentPlan()).then((currentPlan: any) => {
        setLoading(0);
        if (
          plans?.payload?.success &&
          Array.isArray(plans.payload.subscriptions) &&
          plans.payload.subscriptions.length
        ) {
          plans.payload.subscriptions.forEach((plan: any) => {
            if (
              plan.id ===
              currentPlan.payload?.current_subscription?.subscription
                ?.subscription_plan_id
            ) {
              setSubscriptionMonth(plan);
            }
          });
        }
      });
    });
  }, []);

  const closingToast = (e: any) => {
    const changeData: object = { show: false, msg: "" };
    e = { ...e, changeData };
    dispatch(callToaster(e));
  };

  const handlePaymentModal = () => {
    setLoading(1);
    dispatch(currentPlan()).then((currentPlan: any) => {
      setLoading(0);
      if (Array.isArray(allPlans) && allPlans.length) {
        allPlans.forEach((plan: any) => {
          if (
            plan.id ===
            currentPlan.payload?.current_subscription?.subscription
              ?.subscription_plan_id
          ) {
            setSubscriptionMonth(plan);
          }
        });
      }
    });
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };

  const startDate = activePlan?.current_subscription?.subscription?.start_date;
  const endDate = activePlan?.current_subscription?.subscription?.end_date;
  const formatStartDate = formatDate(startDate);
  const formatEndDate = formatDate(endDate);

  useEffect(() => {
    if (reduxData?.auth?.showToast) {
      toast.success(reduxData?.auth?.showToastMessage, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        onClose: (e) => closingToast(e),
        theme: "dark",
      });
    }
  }, []);

  const handlePlan = (obj: any) => {
    if (
      activePlan?.current_subscription?.subscription?.id &&
      activePlan?.has_cancelled_subscription === false
    ) {
      toast.error("Please cancel your current subscription and try again", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
        theme: "dark",
      });
      return;
    }
    setSubscriptionMonth(obj);
  };

  return (
    <>
      <CancelSubscriptionPopup />
      {loading ? <Loader /> : ""}
      <div className="sr-multicol-media bg-white pt-1 pt-lg-4 text-center prototype-no-background widget_1600979911688 bg-secondary ">
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-black"} />
            </div>
          </div>
        </div>
      </div>
      <h2
        className=" section-heading font-inter text-black text-center dash-heading"
        style={{ backgroundColor: "white" }}
      >
        Subscription
      </h2>
      <div
        id="hs_cos_wrapper_widget_34472050333"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget bg-secondary "
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {status === false ? (
          ""
        ) : (
          <>
            <div className="sr-offer-bar-03  bg-white prototype-no-background widget_34472050333 text-white pt-5">
              <div className="container-fluid">
                <div className="row">
                  <ToastContainer />
                  <div className="col-md-10 offset-md-1  ">
                    <div className="row">
                      <div className="col-12 d-flex align-items-center ">
                        <div className="col-12 d-flex align-items-center bg-primary p-0 pt-2 pb-2 flex-column flex-md-row flex-lg-row">
                          <div className="col-md-8 sr-col sr-offer-bar-03-content py-2">
                            <span className="align-middle mx-auto">
                              <h3
                                className="heading text-white font-inter m-0 text-left current-plan-heading"
                                style={{ fontSize: "1.1rem" }}
                              >
                                {activePlan?.current_subscription
                                  ?.subscription === null &&
                                activePlan?.has_cancelled_subscription ===
                                  true &&
                                activePlan?.trial_enabled === true ? (
                                  <>
                                    {/* below line :For Our {(subscriptionMonth ? `${subscriptionMonth.plan_name} Plan`:``)} */}
                                    <strong>
                                      Start Your 7-Day <span>FREE</span> Trial
                                      with one of our plans today.{" "}
                                    </strong>
                                    &nbsp;
                                    <Link
                                      to="/your-payment-history"
                                      className="viewtext"
                                    >
                                      View History
                                    </Link>
                                    <br />
                                    <small className="mob-fontsize">
                                      After your trial ends, you will be
                                      automatically billed ($
                                      {subscriptionMonth.price} per{" "}
                                      {subscriptionMonth.plan_name === "MONTHLY"
                                        ? "month"
                                        : subscriptionMonth.plan_name ===
                                          "THREE MONTH"
                                        ? "quarter"
                                        : subscriptionMonth.plan_name ===
                                          "ONE YEAR"
                                        ? "year"
                                        : subscriptionMonth.plan_name}
                                      ) unless you choose to cancel your
                                      subscription.
                                    </small>{" "}
                                  </>
                                ) : activePlan?.subscriptions_history?.length >
                                    0 &&
                                  activePlan?.current_subscription
                                    ?.subscription === null ? (
                                  <>
                                    Please select any plan &nbsp;
                                    <Link
                                      to="/your-payment-history"
                                      className="viewtext"
                                    >
                                      View History
                                    </Link>
                                    <p className="pt-1 subscription-header-sub-header">
                                      You have cancelled your FREE Trial plan.
                                    </p>
                                  </>
                                ) : activePlan?.current_subscription
                                    ?.subscription?.id &&
                                  activePlan?.has_cancelled_subscription ===
                                    true ? (
                                  <>
                                    Please select any plan &nbsp;
                                    <Link
                                      to="/your-payment-history"
                                      className="viewtext"
                                    >
                                      View History
                                    </Link>
                                    <p className="pt-1 subscription-header-sub-header">
                                      You have cancelled your{" "}
                                      {
                                        activePlan?.current_subscription?.plan
                                          ?.plan_name
                                      }{" "}
                                      plan, the plan will be deactivated on{" "}
                                      {formatEndDate}.
                                    </p>{" "}
                                  </>
                                ) : activePlan?.current_subscription
                                    ?.subscription?.id &&
                                  activePlan?.has_cancelled_subscription ===
                                    false ? (
                                  <>
                                    Active Plan:{" "}
                                    {activePlan?.current_subscription?.plan
                                      ?.plan_name === "MONTHLY"
                                      ? "Monthly (1 month)"
                                      : activePlan?.current_subscription?.plan
                                          ?.plan_name === "THREE MONTH"
                                      ? "Quarterly (3 months)"
                                      : activePlan?.current_subscription?.plan
                                          ?.plan_name === "ONE YEAR"
                                      ? "Yearly (12 months)"
                                      : ""}
                                    &nbsp;
                                    <Link
                                      to="/your-payment-history"
                                      className="viewtext"
                                    >
                                      View History
                                    </Link>
                                    <p className="pt-1 subscription-header-sub-header">
                                      Subscription start from {formatStartDate}{" "}
                                      end {formatEndDate}
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </h3>
                            </span>
                          </div>
                          <div className="col-md-4 text-center text-md-right ">
                            <span className="btn-wrapper btn-white-wrapper d-inline-block btn-wrapper">
                              {activePlan?.current_subscription
                                ?.subscription === null &&
                              activePlan?.has_cancelled_subscription ===
                                true ? (
                                <>
                                  {/* <a
                                    // onClick={() => handlePaymentModal(true,false)}
                                    className="cta_button font-inter mob-textsize"
                                    data-toggle="modal"
                                    data-target=".bd-example-modal-lg"
                                    role="button"
                                  >
                                    Continue & Subscribe
                                  </a> */}
                                </>
                              ) : activePlan?.current_subscription?.subscription
                                  ?.id &&
                                activePlan?.has_cancelled_subscription ===
                                  true ? (
                                <>
                                  {/* <a
                                    // onClick={() => handlePaymentModal(true,false)}
                                    className="cta_button font-inter"
                                    data-toggle="modal"
                                    data-target=".bd-example-modal-lg"
                                    role="button"
                                  >
                                    Continue & Subscribe
                                  </a> */}
                                </>
                              ) : activePlan?.current_subscription?.subscription
                                  ?.id &&
                                activePlan?.has_cancelled_subscription ===
                                  false ? (
                                <>
                                  <a
                                    className="cta_button font-inter cursor"
                                    // onClick={cancelSubscription}
                                    role="button"
                                    data-toggle="modal"
                                    data-target="#cancelSubscriptions"
                                  >
                                    Cancel Subscription
                                  </a>
                                </>
                              ) : (
                                ""
                              )}
                              {/* modal start from */}
                              <Elements stripe={stripePromise}>
                                <PaymentModal
                                  handlePaymentModal={handlePaymentModal}
                                  selectedPlan={subscriptionMonth}
                                />
                              </Elements>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* subscription card part*/}
      <div
        id="hs_cos_wrapper_widget_1604099686647"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        <span
          id="hs_cos_wrapper_main_flexible_column"
          className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container bg-secondary"
          style={{}}
          data-hs-cos-general-type="widget_container"
          data-hs-cos-type="widget_container"
        >
          <div
            id="hs_cos_wrapper_widget_1604340283000"
            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
            style={{ background: "#EDF1D1" }}
            data-hs-cos-general-type="widget"
            data-hs-cos-type="module"
          >
            {/* Label For Modules Data Page */}
            <div className="sr-cards-grid-02 bg-white sr-padding-full prototype-no-background widget_1604340283000">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <div className="cards row justify-content-center">
                      {/* Plan Card start */}
                      {allPlans && Array.isArray(allPlans) && allPlans.length
                        ? allPlans.map(
                            (plan: any, index: number) =>
                              plan.plan_name?.toUpperCase() !== "TRIAL" && (
                                <div
                                  className={`${
                                    subscriptionMonth?.id === plan?.id
                                      ? "col-lg-6 col-md-12 card-transform-select pricing-card "
                                      : "col-lg-3 col-md-12 card-transform pricing-card "
                                  } `}
                                  style={{
                                    borderRadius: "20px",
                                  }}
                                >
                                  <div
                                    className={`${
                                      subscriptionMonth?.id === plan?.id
                                        ? "card card-1 text-center  rounded select-plan-border-orange bg-white text-black"
                                        : "card card-1 text-center  rounded not-select-plan-border-orange bg-black text-white"
                                    } `}
                                    style={{
                                      borderRadius: "20px !important",
                                    }}
                                  >
                                    <div className="card-inner">
                                      <div className="text-wrapper">
                                        <p
                                          className={`${
                                            subscriptionMonth?.id === plan?.id
                                              ? "custom-show pb-2"
                                              : "custom-show pt-3 pb-2"
                                          }`}
                                        >
                                          {subscriptionMonth?.id ===
                                          plan?.id ? (
                                            <>
                                              <img
                                                src="images/logo1.svg"
                                                alt="PlaneList"
                                                width={330}
                                                srcSet=""
                                                sizes="(max-width: 178px) 100vw, 178px"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src="images/logo.svg"
                                                alt="PlaneList"
                                                width={330}
                                                srcSet=""
                                                sizes="(max-width: 178px) 100vw, 178px"
                                              />
                                            </>
                                          )}
                                        </p>
                                      </div>

                                      <div
                                        className={`${
                                          subscriptionMonth?.id === plan?.id
                                            ? "text-black membership"
                                            : "text-white  membership"
                                        } `}
                                      >
                                        {plan.plan_name === "MONTHLY"
                                          ? "Silver Membership"
                                          : plan.plan_name === "THREE MONTH"
                                          ? "Gold Membership"
                                          : plan.plan_name === "ONE YEAR"
                                          ? "Platinum Membership"
                                          : plan.plan_name}
                                      </div>
                                      <div className="row">
                                        <div
                                          className={`${
                                            subscriptionMonth?.id === plan?.id
                                              ? "col-md-6 "
                                              : "col-md-12 d-flex justify-content-center"
                                          } `}
                                        >
                                          <div className="card-image">
                                            {" "}
                                            <div
                                              className={`${
                                                subscriptionMonth?.id ===
                                                plan?.id
                                                  ? "pricing-circle bg-black"
                                                  : "pricing-circle"
                                              } `}
                                            >
                                              <h1>
                                                <strong>
                                                  {" "}
                                                  ${plan?.price}{" "}
                                                </strong>
                                              </h1>
                                              <h6 className="color-orange">
                                                {plan?.duration} {plan?.tenure}
                                              </h6>
                                            </div>{" "}
                                          </div>
                                          {/* 
                                          <strong className="pt-3 pb-3 mt-1 font-inter mob-textsize">
                                            {plan?.total_query === 10
                                              ? "10 Free"
                                              : plan?.total_query ||
                                                "Unlimited"}{" "}
                                            Downloads
                                          </strong> */}
                                        </div>

                                        <div
                                          className={`${
                                            subscriptionMonth?.id === plan?.id
                                              ? "col-md-6 mb-5 mb-lg-0 "
                                              : "col-md-12 mt-0 mb-5"
                                          } `}
                                        >
                                          <h4
                                            className={`${
                                              subscriptionMonth?.id === plan?.id
                                                ? " pricing-card-head font-inter custom-month-color"
                                                : " pricing-card-head font-inter"
                                            } `}
                                          >
                                            {plan?.plan_name === "THREE MONTH"
                                              ? "QUARTERLY"
                                              : plan?.plan_name === "ONE YEAR"
                                              ? "YEARLY"
                                              : plan?.plan_name}
                                          </h4>
                                          <div className="d-flex justify-content-center">
                                            <span className="fs-small">
                                              {plan?.description
                                                ? (() => {
                                                    const data =
                                                      plan?.description;
                                                    // Split the string into an array using <br> tag as delimiter
                                                    const dataArray =
                                                      data.split("<br>");
                                                    return dataArray.map(
                                                      (
                                                        item: any,
                                                        index: any
                                                      ) => (
                                                        <>
                                                          <div className="d-flex align-items-center">
                                                            <div>
                                                              {subscriptionMonth?.id ===
                                                              plan?.id ? (
                                                                <>
                                                                  <CheckMarkIcon
                                                                    style={{
                                                                      alt: "",
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  />
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <CheckMarkIcon
                                                                    style={{
                                                                      alt: "",
                                                                      color:
                                                                        "white",
                                                                    }}
                                                                  />
                                                                </>
                                                              )}
                                                            </div>
                                                            <div className="pl-2">
                                                              <span key={index}>
                                                                {item}
                                                                <br />
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </>
                                                      )
                                                    );
                                                  })()
                                                : ""}
                                              {/* {plan?.description}{" "} */}
                                            </span>
                                          </div>
                                        </div>
                                        {subscriptionMonth.id === plan?.id ? (
                                          <div className="d-flex justify-content-center w-100 subscribe-button-container pt-5">
                                            {/* <div
                                              className={`btn-wrapper ${
                                                activePlan?.current_subscription
                                                  ?.subscription
                                                  ?.subscription_plan_id ===
                                                plan.id
                                                  ? "btn-primary text-white"
                                                  : "btn-outline-primary-wrapper"
                                              }`}
                                            >
                                              <button
                                                className={`cta_button font-inter mob-textsize ${
                                                  activePlan
                                                    ?.current_subscription
                                                    ?.subscription?.id
                                                    ? "text-white"
                                                    : ""
                                                }`}
                                                onClick={() => handlePlan(plan)}
                                                disabled={
                                                  activePlan
                                                    ?.current_subscription
                                                    ?.subscription?.id
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {activePlan
                                                  ?.current_subscription
                                                  ?.subscription
                                                  ?.subscription_plan_id ===
                                                plan.id
                                                  ? "Subscribed"
                                                  : "Continue"}
                                              </button>
                                            </div> */}
                                            <span className="btn-wrapper btn-outline-primary-wrapper">
                                              {activePlan?.current_subscription
                                                ?.subscription === null &&
                                              activePlan?.has_cancelled_subscription ===
                                                true ? (
                                                <>
                                                  <a
                                                    // onClick={() => handlePaymentModal(true,false)}
                                                    className="cta_button font-inter mob-textsize"
                                                    data-toggle="modal"
                                                    data-target=".bd-example-modal-lg"
                                                    role="button"
                                                  >
                                                    Continue
                                                  </a>
                                                </>
                                              ) : activePlan
                                                  ?.current_subscription
                                                  ?.subscription?.id &&
                                                activePlan?.has_cancelled_subscription ===
                                                  true ? (
                                                <>
                                                  <a
                                                    // onClick={() => handlePaymentModal(true,false)}
                                                    className="cta_button font-inter"
                                                    data-toggle="modal"
                                                    data-target=".bd-example-modal-lg"
                                                    role="button"
                                                  >
                                                    Continue
                                                  </a>
                                                </>
                                              ) : activePlan
                                                  ?.current_subscription
                                                  ?.subscription?.id &&
                                                activePlan?.has_cancelled_subscription ===
                                                  false ? (
                                                <>
                                                  <div
                                                    className={`btn-wrapper ${
                                                      activePlan
                                                        ?.current_subscription
                                                        ?.subscription
                                                        ?.subscription_plan_id ===
                                                      plan.id
                                                        ? "btn-primary text-white"
                                                        : "btn-outline-primary-wrapper"
                                                    }`}
                                                  ></div>
                                                  <button
                                                    className={`cta_button font-inter mob-textsize ${
                                                      activePlan
                                                        ?.current_subscription
                                                        ?.subscription?.id
                                                        ? ""
                                                        : ""
                                                    }`}
                                                    // onClick={cancelSubscription}
                                                    // role="button"
                                                    // data-toggle="modal"
                                                    // data-target="#cancelSubscriptions"
                                                    disabled={
                                                      activePlan
                                                        ?.current_subscription
                                                        ?.subscription?.id
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {activePlan
                                                      ?.current_subscription
                                                      ?.subscription?.id
                                                      ? activePlan
                                                          ?.current_subscription
                                                          ?.subscription
                                                          ?.subscription_plan_id ===
                                                        plan.id
                                                        ? "Subscribed"
                                                        : "Continue"
                                                      : "Subscribed"}
                                                  </button>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {/* modal start from */}
                                              <Elements stripe={stripePromise}>
                                                <PaymentModal
                                                  handlePaymentModal={
                                                    handlePaymentModal
                                                  }
                                                  selectedPlan={
                                                    subscriptionMonth
                                                  }
                                                />
                                              </Elements>
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex justify-content-center w-100  subscribe-button-container ">
                                            <div
                                              className={`btn-wrapper ${
                                                activePlan?.current_subscription
                                                  ?.subscription
                                                  ?.subscription_plan_id ===
                                                plan.id
                                                  ? "btn-primary text-white"
                                                  : "btn-outline-primary-wrapper"
                                              }`}
                                            >
                                              <button
                                                className="cta_button font-inter mob-textsize text-white"
                                                onClick={() => handlePlan(plan)}
                                                disabled={
                                                  activePlan
                                                    ?.current_subscription
                                                    ?.subscription
                                                    ?.subscription_plan_id ===
                                                  plan.id
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {activePlan
                                                  ?.current_subscription
                                                  ?.subscription
                                                  ?.subscription_plan_id ===
                                                plan.id
                                                  ? "Subscribed"
                                                  : "Subscribe"}
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )
                        : ""}
                      {/* plan card end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>{" "}
      </div>
    </>
  );
};
