import { FreeAirmen } from "./FreeAirmen";
import "./search.css";
import { useAppSelector } from "../../../hook";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
const NewAirMen = () => {
  const status = useAppSelector((state: any) => state.auth.status);

  return (
    <>
      <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center   prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div className="sr-multicol-media bg-black sr-spacer-bottom-50  text-center prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row pt-md-3">
            <div className="col-md-10 offset-md-1">
              <h2 className=" section-heading font-inter text-white">
                Airmen Records
              </h2>
              <div
                id="hs_cos_wrapper_widget_1604012269835"
                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
                style={{}}
                data-hs-cos-general-type="widget"
                data-hs-cos-type="module"
              >
                <div
                  className="sr-multicol-media bg-black sr-spacer-bottom-50  
          text-center prototype-no-background widget_1604012269835 "
                  id="investors"
                >
                  <div className="container-fluid p-0 pt-0 pt-md-2">
                    <FreeAirmen />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
    </>
  );
};

export default NewAirMen;
