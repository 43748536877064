import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "../../../hook";
import basic1MonthImg from "../../../Assets/PricingImages/Basicw.png";
import basic1MonthImgBlack from "../../../Assets/PricingImages/Basicb.png";
import free from "../../../Assets/PricingImages/Free.png";
import Lorem from "../../../Assets/PricingImages/Lorem.png";
import standardw from "../../../Assets/PricingImages/Standardw.png";
import standardb from "../../../Assets/PricingImages/Standardb.png";
import premiumw from "../../../Assets/PricingImages/Premiumw.png";
import premiumb from "../../../Assets/PricingImages/Premiumb.png";
import "./Pricing.css";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";

export const CancelSubscription = () => {
  const status = useAppSelector((state: any) => state.auth.status);
  const navigate = useNavigate();
  const [subscriptionMonth, setSubscriptionMonth] = useState(0);
  const [subscription, setSubscription] = useState("One Month");
  const [paid, setPaid] = useState(false);

  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);
  const notify = () =>
    toast.success("Payment Successful", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 8000,
      pauseOnFocusLoss: true,
      theme: "dark",
    });
  const [monthlyPlan, setMonthlyPlan] = useState({
    planName: "Basic",
    planType: "One Month",
    planPrice: "$9.95",
    total: "$9.95",
  });

  const [selected, setSelected] = useState("6");
  const oneMonth = () => {
    setSubscriptionMonth(1);
    setMonthlyPlan((prevState) => ({
      ...prevState,
      planName: "Basic",
      planType: "One Month",
      planPrice: "$9.95",
      total: "$9.95",
    }));
    if (!paid) {
      setSubscription("One Month");
    }
    setSelected("6");
  };

  const threeMonth = () => {
    setMonthlyPlan((prevState) => ({
      ...prevState,
      planName: "Standard",
      planType: "Three Month",
      planPrice: "$23.95",
      total: "$23.95",
    }));
    if (!paid) {
      setSubscription("Three Month");
    }
    setSubscriptionMonth(3);
    setSelected("6");
  };

  const oneYear = () => {
    setMonthlyPlan((prevState) => ({
      ...prevState,
      planName: "Premium",
      planType: "One Year",
      planPrice: "$99.95",
      total: "$99.95",
    }));
    if (!paid) {
      setSubscription("One Year");
    }
    setSubscriptionMonth(12);
    setSelected("6");
  };

  const noSubscription = () => {
    setMonthlyPlan((prevState) => ({
      ...prevState,
      planName: "Basic",
      planType: "One Month",
      planPrice: "$9.95",
      total: "$9.95",
    }));
    if (!paid) {
      setSubscription("Start Your Free Trial");
    }

    setSubscriptionMonth(0);
    setSelected("3");
  };
  const paidSuccessfully = () => {
    setPaid(true);
  };

  const imgSrc = subscriptionMonth === 0 ? basic1MonthImg : basic1MonthImgBlack;
  const standardImg = subscriptionMonth === 3 ? standardw : standardb;
  const premiumImg = subscriptionMonth === 12 ? premiumw : premiumb;

  return (
    <>
      <div
        className="sr-multicol-media pt-1 pt-lg-4 text-center prototype-no-background 
       widget_1600979911688 bg-black "
      >
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <h2
        className=" section-heading font-inter text-white text-center dash-heading"
        style={{ backgroundColor: "black", color: "white" }}
      >
        Cancel Subcription
      </h2>
      <div
        id="hs_cos_wrapper_widget_34472050333"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget bg-black"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {/* free trial */}
        {status === false ? (
          <div
            className="sr-offer-bar-03  sr-padding-half  prototype-no-background widget_34472050333 
          text-white pt-md-3"
          >
            <div className="container-fluid">
              <div className="row ">
                <div className="col-md-10 offset-md-1 bg-primary ">
                  <div className="row d-flex align-items-center h-100 sr-offer-bar-03-inner ">
                    <div className="col-lg-12">
                      <div className="row pt-3 pb-3">
                        <div className="col sr-col ">
                          <div className="row d-flex align-items-center h-100 pt-3 pb-3">
                            <div className="col-md-7 sr-col sr-offer-bar-03-content">
                              <span className="align-middle mx-auto">
                                <h3 className=" heading text-white font-inter">
                                  Start Your Free Trial
                                </h3>
                              </span>
                            </div>
                            <div className="col-md-5 text-center text-md-right offer-btns">
                              <span className="btn-wrapper btn-white-wrapper d-inline-block btn-wrapper">
                                <a href="#" className="cta_button font-inter">
                                  Free 7-Day Trial
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className="sr-offer-bar-03  
            prototype-no-background widget_34472050333 text-white pt-5"
            >
              <div className="container-fluid">
                <div className="row">
                  <ToastContainer />
                  <div className="col-md-10 offset-md-1 bg-primary">
                    <div className="row d-flex align-items-center h-100 sr-offer-bar-03-inner ">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col sr-col">
                            <div className="row d-flex align-items-center h-100  p-0 pt-2 pb-2">
                              <div className="col-md-7 sr-col sr-offer-bar-03-content ">
                                <span className="align-middle mx-auto">
                                  <h3
                                    className="heading text-white font-inter m-0 text-left"
                                    style={{ fontSize: "24px" }}
                                  >
                                    {paid && paid ? (
                                      <>
                                        You have subscribed for {subscription}{" "}
                                        plan
                                        {/* <p>Subscription start from</p> */}
                                      </>
                                    ) : (
                                      `Start Your Free Trial`
                                    )}
                                  </h3>
                                </span>
                              </div>
                              <div className="col-md-5 text-center text-md-right offer-btns">
                                <span className="btn-wrapper btn-white-wrapper d-inline-block btn-wrapper">
                                  {paid === false ? (
                                    <a
                                      href="#"
                                      className="cta_button font-inter"
                                      data-toggle="modal"
                                      data-target=".bd-example-modal-lg"
                                    >
                                      Continue & Subscribe
                                    </a>
                                  ) : (
                                    <></>
                                  )}

                                  <div
                                    className="modal fade bd-example-modal-lg"
                                    role="dialog"
                                    aria-labelledby="mySmallModalLabel"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog modal-lg modal-custom-size">
                                      <div
                                        className="modal-content "
                                        style={{ borderRadius: "1rem" }}
                                      >
                                        <div className="modal-header modal-order-summary-header">
                                          <div className="container-fluid">
                                            <div className="row">
                                              <div className="col-8 text-left">
                                                <h5
                                                  className="modal-title  modal-order-summary-header-text "
                                                  id="exampleModalLabel"
                                                >
                                                  Order Summary
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                          <img
                                            src="images/modal-img/cross-img.svg"
                                            className="close custom-close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          ></img>
                                        </div>
                                        <div className="modal-body bg-light ">
                                          <div className="container-fluid">
                                            <div className="row text-black">
                                              <div className="col-md-6 col-sm-12">
                                                {/* DETAILS */}
                                                <div className="col-12 custom-card-border px-4 py-3 text-left font-inter mb-3">
                                                  <div className="header">
                                                    DETAILS
                                                  </div>
                                                  <div className="sub-header">
                                                    MONTHLY PLAN
                                                  </div>
                                                  <div className="d-flex">
                                                    <p className="para">
                                                      7 Day PlaneLists.com
                                                      Membership Trial*
                                                    </p>
                                                    <p className="custom-text-color ml-auto">
                                                      FREE
                                                    </p>
                                                  </div>
                                                  <p className="sub-para">
                                                    *Cancel Anytime, After your
                                                    7 day free trial, you will
                                                    be billed $9.95 per month
                                                  </p>
                                                  <hr></hr>
                                                  <p className="custom-text-color ml-auto">
                                                    Total: Free
                                                  </p>
                                                </div>
                                                <div className="col-12 custom-card-border px-4 py-3 text-left font-inter mb-3">
                                                  <div className="d-flex">
                                                    <div className="mr-3">
                                                      <img
                                                        src="images/modal-img/thumbs-up-black-icon 1.svg"
                                                        className="sub-header-thumbs-up"
                                                      />
                                                    </div>
                                                    <div className="sub-header font-weight-bold ">
                                                      We Are Here to Help You
                                                      <p className="para pt-2">
                                                        If you have questions
                                                        with our product, please
                                                        contact our Customer
                                                        Care team at
                                                        <Link
                                                          to=""
                                                          className="pl-1"
                                                          style={{
                                                            textDecoration:
                                                              "none",
                                                            borderBottom:
                                                              "none",
                                                          }}
                                                        >
                                                         support@planelists.com
                                                        </Link>
                                                        . We are available 7
                                                        days a week to assist
                                                        you
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="d-flex">
                                                    <div className="mr-4">
                                                      <img
                                                        src="images/modal-img/lock-icon (1) 1.svg"
                                                        className="sub-header-lock"
                                                      />
                                                    </div>
                                                    <div className="sub-header font-weight-bold ">
                                                      Your Search History and
                                                      Reports arePrivately
                                                      Stored
                                                      <p className="para pt-2">
                                                        We saved your search
                                                        history and created
                                                        reports for your future
                                                        use. You can access your
                                                        stored reports at any
                                                        time.
                                                      </p>
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* SECURE CHECKOUT */}
                                              <div className="col-md-6 col-sm-12 ">
                                                <div className="col-12 custom-card-border px-4 py-3 text-left font-inter mb-3">
                                                  <div className="header">
                                                    SECURE CHECKOUT
                                                  </div>

                                                  <div className="checkout-sub-header pt-3 pb-2">
                                                    CHOOSE YOUR PAYMENT METHOD
                                                  </div>
                                                  {/* card payment */}
                                                  <div className="payment-method ">
                                                    <input
                                                      type="radio"
                                                      id="css"
                                                      name="fav_language"
                                                      value="CSS"
                                                    />
                                                    <label className="m-0 ml-3 credit-text">
                                                      Credit/Debit Card
                                                    </label>

                                                    <div></div>
                                                    <div></div>
                                                  </div>
                                                  {/* payPal */}
                                                  <div className="payment-method  ">
                                                    <input
                                                      type="radio"
                                                      id="css"
                                                      name="fav_language"
                                                      value="CSS"
                                                    />
                                                    <label className="m-0 ml-3 credit-text">
                                                      payPal
                                                    </label>

                                                    <div></div>
                                                    <div></div>
                                                  </div>
                                                  {/* G-pay */}
                                                  <div className="payment-method ">
                                                    <input
                                                      type="radio"
                                                      id="css"
                                                      name="fav_language"
                                                      value="CSS"
                                                    />
                                                    <label className="m-0 ml-3 credit-text">
                                                      G-pay
                                                    </label>

                                                    <div></div>
                                                    <div></div>
                                                  </div>
                                                  {/* venmo */}
                                                  <div className="payment-method mb-4">
                                                    <input
                                                      type="radio"
                                                      id="css"
                                                      name="fav_language"
                                                      value="CSS"
                                                    />
                                                    <label className="m-0 ml-3 credit-text">
                                                      venmo
                                                    </label>

                                                    <div></div>
                                                    <div></div>
                                                  </div>
                                                  <div>
                                                    <div className="credit-form">
                                                      EMAIL (THIS WILL BE YOUR
                                                      LOGIN)
                                                    </div>
                                                    <div>
                                                      <form>
                                                        <div className="form-row">
                                                          <div className="form-group col-md-12 mt-2">
                                                            <input
                                                              type="email"
                                                              className="form-control input-form"
                                                              id="inputEmail4"
                                                              placeholder="Email"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="form-group">
                                                          <label
                                                            htmlFor="inputAddress"
                                                            className="credit-form"
                                                          >
                                                            NAME ON CARD
                                                          </label>
                                                          <input
                                                            type="text"
                                                            className="form-control input-form"
                                                            id="inputAddress"
                                                            placeholder="ex. John Doe"
                                                          />
                                                        </div>
                                                        <div className="form-group">
                                                          <label className="credit-form ">
                                                            CARD NUMBER
                                                          </label>
                                                          <input
                                                            type="text"
                                                            className="form-control input-form"
                                                            id="inputAddress2"
                                                            placeholder="ex. 1234 1234 1234 1234"
                                                          />
                                                        </div>
                                                        <div className="form-row">
                                                          <div className="form-group col-md-4">
                                                            <label
                                                              htmlFor="inputCity"
                                                              className="credit-form"
                                                            >
                                                              EXP DATE
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="form-control input-form"
                                                              id="inputCity"
                                                              placeholder="MM/YY"
                                                            />
                                                          </div>
                                                          <div className="form-group col-md-4">
                                                            <label
                                                              htmlFor="inputState"
                                                              className="credit-form"
                                                            >
                                                              CVV
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="form-control input-form"
                                                              id="inputCity"
                                                            />
                                                          </div>
                                                          <div className="form-group col-md-4">
                                                            <label
                                                              htmlFor="inputZip"
                                                              className="credit-form"
                                                            >
                                                              ZIP CODE
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="form-control input-form"
                                                              id="inputZip"
                                                            />
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                    <div className="text-center ">
                                                      <div className="d-flex">
                                                        <div
                                                          className=""
                                                          style={{
                                                            width: "11px",
                                                            marginTop: "-2",
                                                          }}
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id="vehicle1"
                                                            name="vehicle1"
                                                            value="Bike"
                                                          />
                                                        </div>
                                                        <div className="col-11 pl-4">
                                                          <p className="i-understand">
                                                            I understand that
                                                            PlaneLists.com is
                                                            not a consumer
                                                            reporting agency.  I
                                                            agree to the
                                                            PlanLists.com Terms
                                                            and Conditions and
                                                            Privacy Policy.
                                                            PlaneLists.com will
                                                            use the personal
                                                            informationI provide
                                                            here by the
                                                            PlaneLists.com
                                                            Privacy Policy. I
                                                            understand that
                                                            unless I cancel
                                                            during my 7-dayfree
                                                            trial period, I will
                                                            be billed $9.95
                                                            monthly. I may
                                                            cancel my account
                                                            hassle-free online
                                                            at any time. All
                                                            sales are final and
                                                            no refunds will be
                                                            issued if
                                                            cancellation is not
                                                            completed before the
                                                            7-day free trial
                                                            period ends.
                                                          </p>
                                                        </div>
                                                      </div>

                                                      <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                                                        <Link to="/subscriptions-list">
                                                          <button
                                                            type="submit"
                                                            data-dismiss="modal"
                                                            aria-label="Close"
                                                            className="custom-freeTrial-btn"
                                                            onClick={() => {
                                                              paidSuccessfully();
                                                              notify();
                                                            }}
                                                          >
                                                            CONTINUE TO PAY
                                                          </button>
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div
        id="hs_cos_wrapper_widget_1604099686647"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        <span
          id="hs_cos_wrapper_main_flexible_column"
          className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container 
          bg-secondary"
          style={{}}
          data-hs-cos-general-type="widget_container"
          data-hs-cos-type="widget_container"
        >
          <div
            id="hs_cos_wrapper_widget_1604340283000"
            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
            style={{ background: "#EDF1D1" }}
            data-hs-cos-general-type="widget"
            data-hs-cos-type="module"
          >
            {/* Label For Modules Data Page */}
            <div className="sr-cards-grid-02 bg-black sr-padding-full prototype-no-background widget_1604340283000">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <div className="cards row">
                      {/* New Style */}
                      <div
                        className={`${
                          subscriptionMonth === 0
                            ? "col-lg-6 col-md-6 card-transform-select"
                            : "col-lg-3 col-md-3 card-transform"
                        } `}
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <div
                          className="card card-1 text-center  rounded "
                          style={{
                            borderRadius: "20px !important",
                          }}
                        >
                          <div className="card-inner">
                            <div className="text-wrapper">
                              <p
                                className={`${
                                  subscriptionMonth === 0
                                    ? "custom-show"
                                    : "custom-hidden"
                                } `}
                              >
                                <img
                                  src="images/logo.svg"
                                  alt="PlaneList"
                                  width={330}
                                  srcSet=""
                                  sizes="(max-width: 178px) 100vw, 178px"
                                />
                              </p>
                            </div>
                            <div className="row">
                              <div
                                className={`${
                                  subscriptionMonth === 0
                                    ? "col-md-6 "
                                    : "col-md-12 "
                                } `}
                              >
                                <div className="card-image sr-spacer-bottom-25">
                                  {" "}
                                  <img
                                    className="monthImg-size"
                                    src={imgSrc}
                                    alt="Superior Inbound Material"
                                  />{" "}
                                </div>

                                <strong className="pt-3 pb-3  font-inter">
                                  10 Free Download
                                </strong>
                              </div>

                              <div
                                className={`${
                                  subscriptionMonth === 0
                                    ? "col-md-6 "
                                    : "col-md-12 mt-0 "
                                } `}
                              >
                                <h4
                                  className={`${
                                    subscriptionMonth === 0
                                      ? " pricing-card-head font-inter custom-month-color"
                                      : " pricing-card-head font-inter"
                                  } `}
                                >
                                  MONTHLY
                                </h4>
                                Price is per month.
                                <br />
                                Your account will be
                                <br />
                                charged each month
                                <br />
                                on the day of purchased
                                <br />
                                until cancelled.
                                <br />
                              </div>
                              <div className=" d-flex justify-content-center w-100">
                                <div className="btn-wrapper btn-outline-primary-wrapper ">
                                  {subscriptionMonth === 0 ? (
                                    <Link
                                      to=""
                                      className="cta_button font-inter"
                                      // onClick={oneMonth}
                                    >
                                      Selected
                                    </Link>
                                  ) : (
                                    <Link
                                      to=""
                                      className="cta_button font-inter"
                                      onClick={noSubscription}
                                    >
                                      JOIN AND SUBSCRIBE
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* new Style */}
                      <div
                        className={`${
                          subscriptionMonth === 3
                            ? "col-lg-6 col-md-6 card-transform-select"
                            : "col-lg-3 col-md-3 card-transform"
                        } `}
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <div
                          className="card card-1 text-center  rounded "
                          style={{
                            borderRadius: "20px !important",
                          }}
                        >
                          <div className="card-inner">
                            <div className="text-wrapper">
                              <p
                                className={`${
                                  subscriptionMonth === 3
                                    ? "custom-show"
                                    : "custom-hidden"
                                } `}
                              >
                                <img
                                  src="images/logo.svg"
                                  alt="PlaneList"
                                  width={330}
                                  srcSet=""
                                  sizes="(max-width: 178px) 100vw, 178px"
                                />
                              </p>
                            </div>
                            <div className="row">
                              <div
                                className={`${
                                  subscriptionMonth === 3
                                    ? "col-md-6 "
                                    : "col-md-12 "
                                } `}
                              >
                                <div className="card-image sr-spacer-bottom-25">
                                  {" "}
                                  <img
                                    className="monthImg-size"
                                    src={standardImg}
                                    alt="Superior Inbound Material"
                                  />{" "}
                                </div>

                                <strong className="pt-3 pb-3  font-inter">
                                  Unlimited Downloads
                                </strong>
                              </div>

                              <div
                                className={`${
                                  subscriptionMonth === 3
                                    ? "col-md-6 "
                                    : "col-md-12 mt-0 "
                                } `}
                              >
                                <h4
                                  className={`${
                                    subscriptionMonth === 3
                                      ? " pricing-card-head font-inter custom-month-color"
                                      : " pricing-card-head font-inter"
                                  } `}
                                >
                                  Three Months
                                </h4>
                                Price is for 3 Months. <br />
                                Your account will be <br /> charged every third{" "}
                                <br /> month from the date of
                                <br /> purchase until cancelled.
                              </div>
                              <div className=" d-flex justify-content-center w-100">
                                <div className="btn-wrapper btn-outline-primary-wrapper ">
                                  {subscriptionMonth === 3 ? (
                                    <Link
                                      to=""
                                      className="cta_button font-inter"
                                      // onClick={noSubscription}
                                    >
                                      Selected
                                    </Link>
                                  ) : (
                                    <Link
                                      to=""
                                      className="cta_button font-inter"
                                      onClick={threeMonth}
                                    >
                                      JOIN AND SUBSCRIBE
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* new Style */}
                      <div
                        className={`${
                          subscriptionMonth === 12
                            ? "col-lg-6 col-md-6 card-transform-select"
                            : "col-lg-3 col-md-3 card-transform"
                        } `}
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <div
                          className="card card-1 text-center  rounded "
                          style={{
                            borderRadius: "20px !important",
                          }}
                        >
                          <div className="card-inner">
                            <div className="text-wrapper">
                              <p
                                className={`${
                                  subscriptionMonth === 12
                                    ? "custom-show"
                                    : "custom-hidden"
                                } `}
                              >
                                <img
                                  src="images/logo.svg"
                                  alt="PlaneList"
                                  width={330}
                                  srcSet=""
                                  sizes="(max-width: 178px) 100vw, 178px"
                                />
                              </p>
                            </div>
                            <div className="row">
                              <div
                                className={`${
                                  subscriptionMonth === 12
                                    ? "col-md-6 "
                                    : "col-md-12 "
                                } `}
                              >
                                <div className="card-image sr-spacer-bottom-25">
                                  {" "}
                                  <img
                                    className="monthImg-size"
                                    src={premiumImg}
                                    alt="Superior Inbound Material"
                                  />{" "}
                                </div>

                                <strong className="pt-3 pb-3  font-inter">
                                  Unlimited Downloads
                                </strong>
                              </div>

                              <div
                                className={`${
                                  subscriptionMonth === 12
                                    ? "col-md-6 "
                                    : "col-md-12 mt-0 "
                                } `}
                              >
                                <h4
                                  className={`${
                                    subscriptionMonth === 12
                                      ? " pricing-card-head font-inter custom-month-color"
                                      : " pricing-card-head font-inter"
                                  } `}
                                >
                                  One Year
                                </h4>
                                Price is for One Year. <br />
                                Your account will be charged <br /> yearly from
                                the date <br />
                                of purchased until <br /> cancelled.
                              </div>
                              <div className=" d-flex justify-content-center w-100">
                                <div className="btn-wrapper btn-outline-primary-wrapper ">
                                  {subscriptionMonth === 12 ? (
                                    <Link
                                      to=""
                                      className="cta_button font-inter"
                                      // onClick={noSubscription}
                                    >
                                      Selected
                                    </Link>
                                  ) : (
                                    <Link
                                      to=""
                                      className="cta_button font-inter"
                                      onClick={oneYear}
                                    >
                                      JOIN AND SUBSCRIBE
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-9 col-md-6 ">
                        <div className="card card-4 text-center">
                          <div className="card-inner">
                            <h4 className="pricing-card-head font-inter">
                              Lorem ipsum
                            </h4>
                            <div className="row">
                              <div className="col-md-4">
                                <img src={Lorem} alt="48c" />
                                <p>
                                  <br />
                                  Lorem ipsum
                                </p>
                              </div>
                              <div className="col-md-4">
                                <img src={Lorem} alt="12c" />
                                <p>
                                  <br />
                                  Lorem ipsum
                                </p>
                              </div>
                              <div className="col-md-4">
                                <img src={Lorem} alt="2c" />
                                <p>
                                  <br />
                                  Lorem ipsum
                                </p>
                              </div>
                            </div>
                            <div className="text-wrapper font-inter ">
                              Lorem ipsum dolor sit, amet consectetur
                              adipisicing elit. Aperiam corporis perspiciatis
                              sit? Placeat, sequi? Incidunt, aspernatur facilis
                              voluptatum veritatis ullam quibusdam sequi minima
                              sint similique temporibus quaerat corrupti,
                              molestias tempore? <br />* With a List Automator
                              Subscription
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 ">
                        <div className="card card-2 text-center">
                          <div className="card-inner">
                            <div className="card-image sr-spacer-bottom-25">
                              {" "}
                              <img
                                src={free}
                                alt="Superior Inbound Material"
                              />{" "}
                            </div>
                            <h4 className="pricing-card-head font-inter">
                              Lorem ipsum{" "}
                            </h4>
                            <div className="text-wrapper font-inter ">
                              Lorem ipsum dolor sit,
                              <br />
                              amet consectetur adipisicing
                              <br />
                              corporis perspiciatis sit
                              <br />
                              <div className="btn-wrapper btn-outline-primary-wrapper sr-spacer-top-25">
                                {" "}
                                <Link to="" className="cta_button font-inter">
                                  SEE MORE
                                </Link>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>{" "}
      </div>
    </>
  );
};
