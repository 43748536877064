import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hook";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import SearchPage from "./Search";
import "./quickSearch.css";
import { useEffect } from "react";

export const QuickSearch = () => {

  const status = useAppSelector((state:any) => state.auth.status);
  const navigate = useNavigate()
  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  return (
    <>
      <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="sr-multicol-media bg-black sr-spacer-bottom-50  text-center prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row pt-md-3">
            <div className="col-md-10 offset-md-1 custom-heading-border ">
              <h2 className=" section-heading font-inter text-white">
                Quick Search
              </h2>
              <div className="font-inter text-white h6 pt-1 mob-textsize para-media-query">
                Here, you can instantly download the latest updated pilot and
                aircraft records.
              </div>
              <div className="row pb-3 pt-md-5 pt-2">
                <div className="col-md-6  sr-col text-left text-white sr-repeater-1 custom-heading-border ">
                  <h5 className="header font-inter">New Pilot Records</h5>
                  <p className="justify-content pt-2 mob-textsize para-media-query">
                    <strong > Search by:</strong> Ratings, aircraft types and
                    latest additions to a pilot certificate.
                  </p>
                  <p className="justify-content pt-2 mob-textsize para-media-query">
                    Want to see the latest student or turbine rated pilots this
                    month? How about within a certain State or ZIP Code or
                    distance? All data is current and EASILY exported in your
                    favorite marketing program.
                  </p>
                  <p className="justify-content text-orange pt-2 mob-textsize para-media-query">
                    All pilot data is updated on the 2nd of each month at 0200
                    EDT or EST
                  </p>
                </div>
                <div className="col-md-6 sr-col text-left text-white fw-bolder  sr-repeater-2 custom-heading-border ">
                  <h5 className="header font-inter">New Aircraft Records</h5>
                  <p className="justify-content pt-2 mob-textsize para-media-query">
                    Quickly find out what aircraft make, models, type etc. has
                    been registered overnight. Select by Manufacturer, Type,
                    Engine Type and search by State, Geographical Region, ZIP
                    Code and Distance.
                  </p>
                  <p className="justify-content text-orange pt-2 mob-textsize para-media-query">
                    Perfect for aircraft aviation supply companies who want the
                    very latest and most accurate sales leads. But wait...!
                  </p>
                  <p className="justify-content pt-2 mob-textsize para-media-query">
                    You can also cross reference the New Pilot Records at the
                    same time. Simply click the check box where it says: "Search
                    and Include Airman and Pilot Data". Easily exportable to your
                    favorite marketing and CRM program via .csv and exportable
                    as well to convenient Avery Label Format. All Aircraft Data
                    is updated at 0200 EDT or EST every day to guarantee the
                    most current and accurate information available.
                  </p>
                </div>
              </div>
              <hr className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <SearchPage />
    </>
  );
};
