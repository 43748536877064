import "../History/history.css";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { useAppSelector } from "../../../hook";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";

export const About = () => {
  const status = useAppSelector((state) => state.auth.status);

  return (
    <>
      <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center   prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>

      <div
        id="hs_cos_wrapper_widget_1604012269835"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/*  About US content */}
        <div
          className="sr-multicol-media bg-black sr-padding-half 
          text-center prototype-no-background widget_1604012269835 "
          id="research-property"
        >
          <div className="container-fluid content-wrapper">
            <div className="row pt-md-4">
              <div className="col-md-10 offset-md-1">
                <h2 className=" section-heading font-inter text-white pb-3 pb-lg-4">
                  About US
                </h2>
                <div className="row  sr-valign-center  custom-para-outline m-4 m-lg-0 flex-column-reverse flex-md-column-reverse flex-lg-row">
                  <div className="col-12 col-lg-6 sr-col text-left  sr-repeater-1">
                    <div className="content-wrapper">
                      <div className="container ">
                        <div className="row  justify-content">
                          <div className="col-12 pt-4 pb-3 text-white">
                            <p>
                            PlaneLists.com (Leading Edge Group, LLC) company first started out using nothing more than a Sinclair ZX80 (eventually a ZX81), learning how to cram BASIC codes into a 16K memory system. Throughout the years, we have built custom relational databases for Aviation Medical Examiners, Aircraft Sales Companies and Dealers, and flight schools. We strive to make our systems as easy to use as possible, but powerful enough to meet your needs. With our advanced server infrastructure, we are able to crunch and coalesce data at a rate that we could only imagine in 1983. All of our programmers and designers are either pilots (Major Airlines and private) or work closely within the aviation community.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 col-lg-6 sr-col  sr-repeater-2 about-outline"
                    style={{
                      margin: "-1px 0px -0px 0px",
                      border: "6px solid beige",
                      animation: "black",
                      padding: "10px",
                   
                    }}
                  >
                    <div className="content-wrapper">
                      <div className="responsive-image ">
                        <img
                          src="images/HomePageImg/aboutPage-img.png"
                          alt="Img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {status ? (
          ""
        ) : (
          //  7-day free trial component
          <FreeTrial />
        )}
      </div>
    </>
  );
};
