import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../Login/login.css";
import { useAppSelector } from "../../../hook";
import { ForgotPassword } from "./ForgotPassword";
import { Loader } from "../RepeatedComponent/Loader";

export const ForgetUserIdOrPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(0);
  const [btnClick, setBtnClick] = useState(true);
  const status = useAppSelector((state: any) => state.auth.status);

  // useEffect(() => {
  //   setLoading(1);
  //   setTimeout(() => {
  //     setLoading(0);
  //   }, 1000);
  // }, [btnClick]);

  useEffect(() => {
    if (status) {
      navigate("/user-dashboard");
      // let a:any={show:true,msg:'Login Successfully'};
      // dispatch(callToaster(a))
    }
  }, [status, navigate]);

  return (
    <>
      {loading ? <Loader /> : ""}
      <div
        className="sr-multicol-media pt-1 pt-lg-4 text-center prototype-no-background 
      widget_1600979911688 bg-black "
      >
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div className="sr-multicol-media bg-black pt-1 text-start prototype-no-background widget_1600979911688">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10 offset-md-1 text-center px-4 d-flex justify-content-center">
              <div className="col-md-9">

   
              <div className="bg-black container-fluid content-wrapper px-0 pt-4 bg-black  d-flex flex-wrap justify-content-around">
                <div className="col-12 d-flex justify-content-center p-0 text-white"> 
                   <div
                    className="col-sm-12  text-white col-lg-12 text-center custom-btn personalInfo-btn-clicked mt-md-0 mt-3 " style={{cursor: "default"}}
                    onClick={() => {
                      setBtnClick(false);
                    }}
                  >
                    Forgot Password
                  </div> 
                </div>
              </div>

              <div className="pb-4">
               <ForgotPassword />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
