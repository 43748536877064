import { Link } from "react-router-dom";
import { Feedback } from "../FooterLinks/Feedback";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  return (
    <>
      <Feedback />
      <div
        id="hs_cos_wrapper_widget_34472050335"
        className=" hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget backgroundImg"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {/* Label For Modules Data Page */}
        <footer
          className="sr-footer sr-footer-01  sr-padding-full footer-content text-light widget_34472050335"
          id="footer"
        >
          <div className="sr-footer-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row sr-spacer-bottom-50">
                    <div className=" col-12 col-md-6 d-flex justify-content-center justify-content-md-between align-items-md-start  ">
                      <div>
                        <p className="pb-4 d-flex justify-content-center">
                          <img
                            src="images/logo.svg"
                            style={{}}
                            alt="PlaneList"
                          />
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 pb-3 fsinc d-flex justify-content-end">
                      PlaneLists.com is The Most Trusted All-in-one Solution
                      That Provides Comprehensive Aviation Data Nationwide
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright container-fluid container-fluid">
            <div className="row no-gutters footer-on-big">
              <div className="col-md-8 offset-md-1">
                <div className="text-light ">
                  <div className="small">
                    <p>
                      Copyright {currentYear} PlaneLists.com - All Rights
                      Reserved
                      <span>|</span>{" "}
                      <Link to="/" onClick={scrollToTop}>
                        Home
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link to="/about" onClick={scrollToTop}>
                        About
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link to="/contact" onClick={scrollToTop}>
                        Contact
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link to="/privacy-policy" onClick={scrollToTop}>
                        Privacy Policy
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link
                        to="/terms"
                        onClick={scrollToTop}
                        // data-toggle="modal"
                        // data-target="#exampleModalCenter"
                      >
                        Terms of Service
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 text-md-right socialFooter">
                <div className="d-flex justify-content-end  pt-1 pb-1">
                  {/* Right Menu */}
                  <span className="hs_cos_wrapper_widget_1600383188162_">
                    <div
                      className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                      role="navigation"
                      data-sitemap-name="default"
                      data-menu-id="83812160326"
                      aria-label="Navigation Menu"
                    >
                      <ul role="menu" style={{ display: "flex", gap: "9px" }}>
                        <li
                          className="hs-menu-item hs-menu-depth-1"
                          role="none"
                        >
                          <Link
                            to="/"
                            role="menuitem"
                            target="_blank"
                            rel="noopener"
                          >
                            <i className="fab fa-facebook" aria-hidden="true" />
                          </Link>
                        </li>
                        <li
                          className="hs-menu-item hs-menu-depth-1"
                          role="none"
                        >
                          <Link
                            to="/"
                            role="menuitem"
                            target="_blank"
                            rel="noopener"
                          >
                            <i className="fab fa-twitter" aria-hidden="true" />
                          </Link>
                        </li>
                        <li
                          className="hs-menu-item hs-menu-depth-1"
                          role="none"
                        >
                          <Link
                            to="/"
                            role="menuitem"
                            target="_blank"
                            rel="noopener"
                          >
                            <i className="fab fa-linkedin" aria-hidden="true" />
                          </Link>
                        </li>
                        <li
                          className="hs-menu-item hs-menu-depth-1"
                          role="none"
                        >
                          <Link
                            to="/"
                            role="menuitem"
                            target="_blank"
                            rel="noopener"
                          >
                            <i
                              className="fab fa-instagram"
                              aria-hidden="true"
                            />
                          </Link>
                        </li>
                        <li
                          className="hs-menu-item hs-menu-depth-1"
                          role="none"
                        >
                          <Link
                            to="/"
                            role="menuitem"
                            target="_blank"
                            rel="noopener"
                          >
                            <i className="fab fa-youtube" aria-hidden="true" />
                          </Link>
                        </li>
                        <li
                          className="hs-menu-item hs-menu-depth-1"
                          role="none"
                        >
                          <Link
                            to="/"
                            role="menuitem"
                            target="_blank"
                            rel="noopener"
                          >
                            <i className="fab fa-tiktok" aria-hidden="true" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="row no-gutters footer-on-small">
              <div className="col-md-10 offset-md-1">
                <div className="text-light ">
                  <div className="small">
                    <p>
                      <div className="d-flex justify-content-center">
                        {/* <span>|</span>{" "} */}
                        <Link to="/" className="fd-big" onClick={scrollToTop}>
                          {" "}
                          Copyright {currentYear} PlaneLists.com - All Rights
                          Reserved{" "}
                        </Link>
                        {/* <span>|</span>{" "} */}
                      </div>
                      <div className="d-flex justify-content-center my-2">
                        <Link className="fd-big" to="/" onClick={scrollToTop}>
                          Home
                        </Link>{" "}
                        <span>|</span>{" "}
                        <Link
                          className="fd-big"
                          to="/about"
                          onClick={scrollToTop}
                        >
                          About
                        </Link>{" "}
                        <span>|</span>{" "}
                        <Link
                          className="fd-big"
                          to="/contact"
                          onClick={scrollToTop}
                        >
                          Contact
                        </Link>{" "}
                        <span>|</span>{" "}
                        <Link
                          className="fd-big"
                          to="/privacy-policy"
                          onClick={scrollToTop}
                        >
                          Privacy Policy
                        </Link>{" "}
                        <span>|</span>{" "}
                        <Link
                          className="fd-big"
                          to="/privacy-policy"
                          onClick={scrollToTop}
                        ></Link>{" "}
                        <Link
                          className="fd-big mobile-hide"
                          to="/terms"
                          onClick={scrollToTop}
                        >
                          Terms of Service
                        </Link>{" "}
                      </div>
                      <div className="mobile-show justify-content-center">
                        <Link
                          className="fd-big"
                          to="/terms"
                          onClick={scrollToTop}
                        >
                          Terms of Service
                        </Link>{" "}
                      </div>

                      <div className="d-flex justify-content-center  pt-1 pb-1">
                        {/* Right Menu */}
                        <span className="hs_cos_wrapper_widget_1600383188162_">
                          <div
                            className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                            role="navigation"
                            data-sitemap-name="default"
                            data-menu-id="83812160326"
                            aria-label="Navigation Menu"
                          >
                            <ul
                              role="menu"
                              style={{ display: "flex", gap: "9px" }}
                            >
                              <li
                                className="hs-menu-item hs-menu-depth-1"
                                role="none"
                              >
                                <Link
                                  to="/"
                                  role="menuitem"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i
                                    className="fab fa-facebook"
                                    aria-hidden="true"
                                  />
                                </Link>
                              </li>
                              <li
                                className="hs-menu-item hs-menu-depth-1"
                                role="none"
                              >
                                <Link
                                  to="/"
                                  role="menuitem"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i
                                    className="fab fa-twitter"
                                    aria-hidden="true"
                                  />
                                </Link>
                              </li>
                              <li
                                className="hs-menu-item hs-menu-depth-1"
                                role="none"
                              >
                                <Link
                                  to="/"
                                  role="menuitem"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i
                                    className="fab fa-linkedin"
                                    aria-hidden="true"
                                  />
                                </Link>
                              </li>
                              <li
                                className="hs-menu-item hs-menu-depth-1"
                                role="none"
                              >
                                <Link
                                  to="/"
                                  role="menuitem"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i
                                    className="fab fa-instagram"
                                    aria-hidden="true"
                                  />
                                </Link>
                              </li>
                              <li
                                className="hs-menu-item hs-menu-depth-1"
                                role="none"
                              >
                                <Link
                                  to="/"
                                  role="menuitem"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i
                                    className="fab fa-youtube"
                                    aria-hidden="true"
                                  />
                                </Link>
                              </li>
                              <li
                                className="hs-menu-item hs-menu-depth-1"
                                role="none"
                              >
                                <Link
                                  to="/"
                                  role="menuitem"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i
                                    className="fab fa-tiktok"
                                    aria-hidden="true"
                                  />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
