import { useAppSelector } from "../../../hook";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import "../AME-PatientLeads/ameMap.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./aviation.css";
import { OffMarketForm } from "./OffMarketForm";

const AviationServices = () => {
  const status = useAppSelector((state: any) => state.auth.status);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const togglePopup = () => {
    setShowPopup((prevState) => !prevState);
  };

  return (
    <>
      <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center   prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div className="sr-multicol-media bg-black sr-spacer-bottom-50  text-center prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row pt-md-3">
            <div className="col-md-10 offset-md-1">
              <h2 className=" section-heading font-inter text-white">
                Off-Market Aircraft Leads
              </h2>
              <div className="row amemar4  pb-5 offMarket-flexcolumn">
                <div className="col-12 col-lg-6 nopadding sr-col text-left text-white sr-repeater-1">
                  <div className="content-wrapper">
                    <div className="col-content mob-textsize">
                      <p>
                        <strong className="font-inter heading-media-query">
                          Our easy-to-use US Aircraft Owners database makes it
                          simple to find aviation info without the fuss of going
                          through FAA records.
                        </strong>
                        <div className="mt-2 para-media-query">
                          You can easily search for aircraft by:
                          <div className="pl-1  my-3 ">
                            <div className="d-flex align-items-center ">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                              >
                                <path
                                  d="M3.53221 15.3135C3.35905 15.1441 3.35905 14.8687 3.53221 14.6993L9.37559 8.97012C9.79264 8.56123 9.79291 7.88968 9.3762 7.48045L3.53221 1.74132C3.35905 1.57188 3.35905 1.29653 3.53221 1.12708C3.70537 0.957639 3.98674 0.957639 4.1599 1.12708L11.0657 7.90788C11.1522 7.9926 11.1955 8.09851 11.1955 8.215C11.1955 8.3209 11.1522 8.4374 11.0657 8.52212L4.1599 15.3029C3.98674 15.483 3.70537 15.483 3.53221 15.3135Z"
                                  fill="#E35D22"
                                  stroke="#E35D22"
                                  stroke-width="1.04353"
                                />
                                <path
                                  d="M1.08954 12.2156C0.970154 12.0988 0.970154 11.909 1.08954 11.7921L4.13867 8.81418C4.55759 8.40504 4.55792 7.73135 4.1394 7.3218L1.08954 4.33728C0.970154 4.22045 0.970154 4.03061 1.08954 3.91379C1.20892 3.79697 1.40292 3.79697 1.5223 3.91379L5.54399 7.84932C5.60368 7.90773 5.63353 7.98075 5.63353 8.06106C5.63353 8.13408 5.60368 8.2144 5.54399 8.27281L1.5223 12.2083C1.40292 12.3325 1.20892 12.3325 1.08954 12.2156Z"
                                  fill="#FF7125"
                                  stroke="#FF8F53"
                                  stroke-width="1.04353"
                                />
                              </svg>
                              <span className="mx-3 para-media-query">
                                Type (e.g., single-engine, business jet) Ratings
                              </span>{" "}
                            </div>
                            <div className="d-flex align-items-center ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                              >
                                <path
                                  d="M3.53221 15.3135C3.35905 15.1441 3.35905 14.8687 3.53221 14.6993L9.37559 8.97012C9.79264 8.56123 9.79291 7.88968 9.3762 7.48045L3.53221 1.74132C3.35905 1.57188 3.35905 1.29653 3.53221 1.12708C3.70537 0.957639 3.98674 0.957639 4.1599 1.12708L11.0657 7.90788C11.1522 7.9926 11.1955 8.09851 11.1955 8.215C11.1955 8.3209 11.1522 8.4374 11.0657 8.52212L4.1599 15.3029C3.98674 15.483 3.70537 15.483 3.53221 15.3135Z"
                                  fill="#E35D22"
                                  stroke="#E35D22"
                                  stroke-width="1.04353"
                                />
                                <path
                                  d="M1.08954 12.2156C0.970154 12.0988 0.970154 11.909 1.08954 11.7921L4.13867 8.81418C4.55759 8.40504 4.55792 7.73135 4.1394 7.3218L1.08954 4.33728C0.970154 4.22045 0.970154 4.03061 1.08954 3.91379C1.20892 3.79697 1.40292 3.79697 1.5223 3.91379L5.54399 7.84932C5.60368 7.90773 5.63353 7.98075 5.63353 8.06106C5.63353 8.13408 5.60368 8.2144 5.54399 8.27281L1.5223 12.2083C1.40292 12.3325 1.20892 12.3325 1.08954 12.2156Z"
                                  fill="#FF7125"
                                  stroke="#FF8F53"
                                  stroke-width="1.04353"
                                />
                              </svg>{" "}
                              <span className="mx-3 para-media-query">
                                Expired medical certificate
                              </span>{" "}
                            </div>
                            <div className="d-flex align-items-center ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                              >
                                <path
                                  d="M3.53221 15.3135C3.35905 15.1441 3.35905 14.8687 3.53221 14.6993L9.37559 8.97012C9.79264 8.56123 9.79291 7.88968 9.3762 7.48045L3.53221 1.74132C3.35905 1.57188 3.35905 1.29653 3.53221 1.12708C3.70537 0.957639 3.98674 0.957639 4.1599 1.12708L11.0657 7.90788C11.1522 7.9926 11.1955 8.09851 11.1955 8.215C11.1955 8.3209 11.1522 8.4374 11.0657 8.52212L4.1599 15.3029C3.98674 15.483 3.70537 15.483 3.53221 15.3135Z"
                                  fill="#E35D22"
                                  stroke="#E35D22"
                                  stroke-width="1.04353"
                                />
                                <path
                                  d="M1.08954 12.2156C0.970154 12.0988 0.970154 11.909 1.08954 11.7921L4.13867 8.81418C4.55759 8.40504 4.55792 7.73135 4.1394 7.3218L1.08954 4.33728C0.970154 4.22045 0.970154 4.03061 1.08954 3.91379C1.20892 3.79697 1.40292 3.79697 1.5223 3.91379L5.54399 7.84932C5.60368 7.90773 5.63353 7.98075 5.63353 8.06106C5.63353 8.13408 5.60368 8.2144 5.54399 8.27281L1.5223 12.2083C1.40292 12.3325 1.20892 12.3325 1.08954 12.2156Z"
                                  fill="#FF7125"
                                  stroke="#FF8F53"
                                  stroke-width="1.04353"
                                />
                              </svg>{" "}
                              <span className="mx-3 para-media-query">
                                Expired aircraft registration
                              </span>
                            </div>
                            <div className="d-flex align-items-center ">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                              >
                                <path
                                  d="M3.53221 15.3135C3.35905 15.1441 3.35905 14.8687 3.53221 14.6993L9.37559 8.97012C9.79264 8.56123 9.79291 7.88968 9.3762 7.48045L3.53221 1.74132C3.35905 1.57188 3.35905 1.29653 3.53221 1.12708C3.70537 0.957639 3.98674 0.957639 4.1599 1.12708L11.0657 7.90788C11.1522 7.9926 11.1955 8.09851 11.1955 8.215C11.1955 8.3209 11.1522 8.4374 11.0657 8.52212L4.1599 15.3029C3.98674 15.483 3.70537 15.483 3.53221 15.3135Z"
                                  fill="#E35D22"
                                  stroke="#E35D22"
                                  stroke-width="1.04353"
                                />
                                <path
                                  d="M1.08954 12.2156C0.970154 12.0988 0.970154 11.909 1.08954 11.7921L4.13867 8.81418C4.55759 8.40504 4.55792 7.73135 4.1394 7.3218L1.08954 4.33728C0.970154 4.22045 0.970154 4.03061 1.08954 3.91379C1.20892 3.79697 1.40292 3.79697 1.5223 3.91379L5.54399 7.84932C5.60368 7.90773 5.63353 7.98075 5.63353 8.06106C5.63353 8.13408 5.60368 8.2144 5.54399 8.27281L1.5223 12.2083C1.40292 12.3325 1.20892 12.3325 1.08954 12.2156Z"
                                  fill="#FF7125"
                                  stroke="#FF8F53"
                                  stroke-width="1.04353"
                                />
                              </svg>
                              <span className="mx-3 para-media-query">
                                Within a radius of a zip code
                              </span>
                            </div>
                          </div>
                          <div
                            className="my-2 mb-4 amedata ml-1 para-media-query"
                            style={{ textAlign: "justify", padding: "10px" }}
                          >
                            Available in various formats, our downloadable data
                            streamlines the process.
                          </div>
                          <div className="ml-1 para-media-query">
                            Leverage this resource for personalized direct mail
                            campaigns, reaching aircraft owners with a
                            high-probability of selling their aircraft a
                            discounted price.
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                {status ? (
                  <div className="col-12 col-lg-6 sr-col text-left text-white fw-bolder  sr-repeater-2">
                    <OffMarketForm />
                  </div>
                ) : (
                  <div className="col-12 col-lg-6 sr-col text-left text-white fw-bolder  sr-repeater-2 d-flex flex-column justify-content-between">
                    <div>
                      <div className="mb-0 mb-md-1  mb-lg-3 d-flex align-content-center">
                        <div className="btn-wrapper btn-wrapper-sm amecol btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ">
                          <a
                            href=""
                            className="custom-login-btn border-0 bt-new"
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            <img
                              src="images/ameimg/youtube.png"
                              alt="Start"
                              className="mr-1"
                            />
                            <span className="amefin">WATCH VIDEO DEMO</span>
                          </a>

                          <div
                            className="modal fade"
                            id="exampleModal"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content bg-black">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title text-white"
                                    id="exampleModalLabel"
                                  >
                                    <img
                                      src="images/ameimg/youtube.png"
                                      alt="Start"
                                      className="mr-1"
                                    />
                                    <span className="amefin text-primary">
                                      WATCH VIDEO DEMO
                                    </span>
                                  </h5>
                                  <button
                                    type="button"
                                    className="close text-white"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body bg-black">
                                  <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/tup4lkykai4?si=64gfP0upVu_T5LQ8"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-0 mb-md-1  mb-lg-3  d-flex ">
                        <div className="btn-wrapper amecol btn-wrapper-sm btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ">
                          <a
                            href="Csvfile/PostCardSampleCSV.csv"
                            className="custom-login-btn amerp-2  border-0  bt-new"
                          >
                            <img
                              src="images/ameimg/csv.png"
                              alt="Start"
                              className="mr-1"
                            />
                            <span className="amefin">SAMPLE CSV FILE</span>
                          </a>
                        </div>
                      </div>
                      <div className="mb-3 mb-md-2  mb-lg-4  d-flex">
                        <div className="btn-wrapper amecol btn-wrapper-sm btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ">
                          <a
                            href="Csvfile/PostCardSampleCSV.csv"
                            className="custom-login-btn amerp-4  border-0  bt-new"
                          >
                            <img
                              src="images/ameimg/label.png"
                              alt="Start"
                              className="mr-1 "
                            />{" "}
                            <span className="amefin">SAMPLE LABEL FILE </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="content-wrapper amebgColor p-4">
                      <div className=" amefs border-0 pb-2">
                        Start 7-day free Trial
                      </div>
                      <div className="mob-textsize para-media-query">
                        The most up-to-date marketing aviation information in
                        the industry.
                      </div>
                      <div className="mob-textsize para-media-query">
                        No Contract. Cancel Anytime.
                      </div>
                      <div className="d-flex">
                        <div className=" offer-btns d-xl-inline mt-4 mt-xl-0 pt-3">
                          <span className="btn-wrapper btn-white-wrapper d-inline-block btn-wrapper">
                            <Link
                              to="/register"
                              className="cta_button cta-d-hover border-0 font-inter mob-textsize"
                            >
                              Start 7-day free Trial
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {status ? (
        ""
      ) : (
        //  7-day free trial component
        <FreeTrial />
      )}
    </>
  );
};

export default AviationServices;
